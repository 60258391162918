html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}

.module {
  padding-left: 5px;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1.3em;
  color: #c0c0c0;
}

.login {
  &__container {
    display: flex;
    height: 100%;
  }
  &__form-box {
    display: inline-block;
  }
  &__form-box > img {
    max-width: 250px;
  }
  &__left {
    display: flex;
    flex: 1;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat, repeat;
    background-image: url('../images/background-login-form.jpg');
  }
  &__right {
    display: none;
    background-image: url('../images/background-login.jpg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
  }

  @media (min-width: 768px) {
    &__left {
      flex: 2;
    }

    &__right {
      display: flex;
      flex: 3;
    }
  }
}
